<template>
  <div class="view-home">
    <div id="resume-top">
      <div id="first-description">
        <p>
          Societatea <b>STM STRUCTURAL DESIGN GROUP</b> înființată în anul 2020 are ca domeniu principal de activitate proiectarea structurilor de rezistență a 
          constructiilor.
        </p>
        <p>
          Firma noastră oferă servicii profesionale de proiectare a structurii de rezistență pentru construcții civile, industriale sau agricole
          cu destinații variate răspunzând cu promtitudine cerințelor de piață actuale.
        </p>
      </div>
      <div>
        
      </div>
    </div>
    <div id="resume-bottom">
      <div id="resume-bottom-left">
        <div>
          <ul>
            <li>
              realizarea proiectelor de rezistență
            </li>
            <li>
              consultanță și asistență tehnică
            </li>
            <li>
              preluarea și optimizarea proiectelor
            </li>
          </ul>
        </div>
        <div class="carte-vizita">
          <p class="firm-name">
            <span>S.C. STM STRUCTURAL DESIGN GROUP S.R.L.</span>
          </p>
          <table>
            <tr>
              <td>
                tel:
              </td>
              <td>
                <a href="tel:0748-600393">0748-600393</a>
              </td>
            </tr>
            <tr>
              <td>
                email:
              </td>
              <td>
                <a href="mailto:stmstructuraldesign@gmail.com">stmstructuraldesign@gmail.com</a>
              </td>
            </tr>
            <tr>
              <td>
                adresa:
              </td>
              <td>
                Cluj-Napoca, Str. Calea Dorobantilor nr. 38
              </td>
            </tr>
            <tr>
              <td>
              </td>
              <td class="adress-detail">
                Et. 1, Ap. 14
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div>
        <transition-group name="fade" tag="div">
          <div v-for="index in [currentIndex]" :key="index">
              <img :src="currentImg" :alt="'struct image'" />
          </div>
        </transition-group>
        <div style="display: flex; justify-content: center">
          <a class="prev" @click="prev" href="#">&#10094; precedent &nbsp;&nbsp;&nbsp;</a>
          <div style="word-break: keep-all; text-align: center">{{currentDescription}}</div>
          <a class="next" @click="next" href="#">&nbsp;&nbsp;&nbsp; urmator &#10095;</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppHome',
  data () {
    return {
      homePagePhotos: [
          {
              img: '1_home_s.png',
              description: '',
              show: true
          },
          {
              img: '2_home_s.png',
              description: '',
              show: false
          },
          {
              img: '3_home_s.png',
              description: '',
              show: false
          },
          {
              img: '4_home_s.png',
              description: '',
              show: false
          },
      ],
      currentIndex: 0,
      timer: null,
    }
  },
  mounted: function () {
    this.startSlide();
  },
  computed: {
    currentImg: function() {
      return require('../assets/img/homePage/' + this.homePagePhotos[Math.abs(this.currentIndex) % this.homePagePhotos.length].img);
    },
    currentDescription: function() {
      return this.homePagePhotos[Math.abs(this.currentIndex) % this.homePagePhotos.length].description;
    }
  },  
  methods: {
      startSlide: function () {
          this.timer = setInterval(this.next, 10000);
      },
      next: function () {
          this.currentIndex += 1;
      },
      prev: function () {
          this.currentIndex -= 1;
      }
  },
  
}
</script>

<style scoped>
  
  .view-home {
    height: 81vh;
    margin-top: 30px;
    margin-left: 10px;
    overflow-y: auto;
  }

  #resume-top {
    display: flex;
    font-size: 14pt;
  }

  #resume-bottom {
    margin-top: 5%;
    display: flex;
    justify-content: space-around;
    font-size: 14pt;
    font-style: italic;
    padding-right: 10px;
  }

  #resume-bottom-left {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    width: 35%;
    max-width: 35%;
  }

  #resume-bottom-left div {
    margin-top: 50px;
  }

  #resume-bottom img {
    max-height: 55vh;
  }

  #first-description {
    text-align: justify;
    margin-right: 15px;
  }

  #resume-bottom li {
    text-align: left;
    font-size: 24pt;
    font-weight: bolder;
    margin-bottom: 25px;
  }

  #detail-description {
    padding-right: 10px;
  }

  #detail-description p {
    text-align: justify;
    font-size: 12pt;
  }

  #detail-description ul {
    list-style: none;
  }

  .carte-vizita {
    text-align: center;
  }

  .carte-vizita .firm-name {
    display: flex;
    align-items: center;
    font-size: 14pt;
    font-weight: bold;
  }

  .carte-vizita img {
    height: 85px;
    max-height: 85px;
    margin-right: 5px;
  }

  .carte-vizita td {
    text-align: left;
  }

  .carte-vizita a {
    text-decoration: none;
    color: #2c3e50;
  }

  .carte-vizita .adress-detail {
    text-align: right;
  }
  
  .prev, .next {
    cursor: pointer;
    width: auto;
    padding: 4px;
    font-size: 10px;
    border-radius: 0 4px 4px 0;
    text-decoration: none;
    user-select: none;
  }

  .prev:hover, .next:hover {
    background-color: rgba(0,0,0,0.9);
    color: white;
  }

  @media screen and (max-width: 1366px) {

    .view-home {
      text-align: left;
    }

    #resume-top {
      margin-top: 5px;
      flex-direction: column;
      font-size: 12pt;
    }

    #resume-bottom {
      margin-top: 15px;
      flex-direction: column;
      font-size: 12pt;
    }

    #resume-bottom-left {
      width: 100%;
      max-width: 100%;
    }

    #resume-bottom-left div {
      margin-top: 5px;
      margin-right: 0;
    }

    #resume-bottom li {
      font-size: 12pt;
    }

    #resume-bottom img {
      max-width: 90vw;
      max-height: 55vh;
    }

    #detail-description p {
      font-size: 12pt;
    }

  }

  @media screen and (max-width: 1366px) and (orientation: landscape) {
    .view-home {
      text-align: center;
    }
  }

</style>
